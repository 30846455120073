<template>
  <v-container>
    <AppLst v-if="tabNumber===1" 
      :itemDoc="doctors"
      :itemRea="reasons"
      :itemApp="appoints"
      :itemGrp="appointsGrp"
      :item="itemRow"
      @tabChange="loadTab"
      @setItemRow="itemRow = $event"
      class="ma-0 pa-0"
      />
    <AppCal v-if="tabNumber===2"
      :itemDoc="doctors"
      :itemRea="reasons"
      :itemApp="appoints"
      :itemGrp="appointsGrp"
      :item="itemRow"
      @tabChange="loadTab"
      @setItemRow="itemRow = $event"
      class="ma-0 pa-0"
      />

    <DIV v-show="urlApi.includes('localhost')">
      TABNUMBER {{tabNumber}}<BR/>
      ITEMROW{{'itemRow'}}*<BR/>
      DOCTORS*{{'doctors'}}*<BR/>
      REASONS*{{'reasons'}}*<BR/>
      appointsGrpr*{{'appointsGrp'}}*<BR/>
      appointsNext*{{'appointsNext'}}*<BR/>
      appoints*{{'appoints'}}*<BR/>
    </DIV>
  </v-container>
</template>
<script>
import AppLst from '@/components/AppLst.vue'
import AppCal from '@/components/AppCal.vue'
  export default {
    name: 'AppLs',
    props: {
      //
    },
    components: {
      AppLst, AppCal
    },
    data: () => ({
      //
      doctors: [],
      reasons: [],
      appoints: [],
      appointsNext: [],
      appointsGrp: [],
      //
      tabNumber: 0,
      itemRow: [],
    }),
    methods: {
      initialize: async function () {
        await this.doctorList();
        await this.reasonList();
        await this.getList();
        await this.setArr();
        this.tabNumber = await 1;
      },
      upDtata: async function () {
        //
      },
      doctorList: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'doctorlist';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.doctors = await dataResponse.data;
          // await alert(JSON.stringify(dataResponse));
        }
      },
      reasonList: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'reasonlist';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.reasons = await dataResponse.data;
          // await alert(JSON.stringify(dataResponse));
        }
      },
      getList: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'appointlist';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.appoints = await dataResponse.data
          // await this.getEvents();
        }
      },
      setArr: async function () {
        //
        const localTime = await new Date();
        // const localDay = await new Date(localTime.getFullYear(), (localTime.getMonth()), localTime.getDate() );
        const timeStampStartDay = await new Date(localTime.getFullYear(), (localTime.getMonth()), localTime.getDate() ).getTime(); // Date.now();
        /*
        await console.log(
          'localTime ' + localTime
        )
        await console.log(
          'localDay ' + localDay
        )
        await console.log(
          'timeStampStartDay ' + timeStampStartDay
        )
        */
        let conta = await 0;
        let arrDateTime = await 0;
        let arrApp = await this.copyObjects(this.appoints)
        let arrNext = await [];
        let arrGrp = await [];
        for (var row in await arrApp) {
          arrDateTime = await this.toTime(arrApp[row]);
          arrApp[row].active = arrApp[row].active === '1' ? true : false;
          if (await arrDateTime >= timeStampStartDay){
            
            // await console.log(
              // arrDateTime
            // )
            
            await arrNext.push(arrApp[row]);
            conta = await conta + 1;
          }
        }
        // await console.log(
          // conta
        // )
        this.appointsNext = await this.copyObjects(arrNext);
        arrApp = await [];
        arrNext = await this.copyObjects(this.appointsNext);
        conta = await 0;
        let arrD = [];
        for (var rowN in await arrNext) {
          conta = await 0;
          if (await arrGrp.length === 0){
            // conta = await conta + 1;
            await arrGrp.push(arrNext[rowN]);
          }
          
          arrD = await arrGrp.find(({ day }) => Number(day) === Number(arrNext[rowN].day));
          
          if (await arrD) {
            //
            conta = await conta + 1;
            // await arrGrp.push(arrNext[rowN]);
          }
          if (await conta === 0) {
            await arrGrp.push(arrNext[rowN]);
          }
          // await console.log(conta);
        }
        // this.appointsGrp = await [{active: false, items:[{active:false}]}];
        this.appointsGrp = await this.copyObjects(arrGrp);
        // await console.log(this.appointsGrp.length);
        
        arrNext = await [];
        arrGrp = await [];
        arrNext = await this.copyObjects(this.appointsNext);
        arrGrp = await this.copyObjects(this.appointsGrp);
        for (var rowGr in await arrGrp) {
          arrGrp[rowGr].hour = await 0;
          arrGrp[rowGr].minute = await 0;
          arrGrp[rowGr].timestamp = await new Date(arrGrp[rowGr].year, (arrGrp[rowGr].month - 1), arrGrp[rowGr].day ).getTime();
          // arrGrp[rowGr].active = arrGrp[rowGr].active === '1' ? true : false;
          arrGrp[rowGr].items = await [];

          arrGrp[rowGr].comment = await undefined;
          arrGrp[rowGr].name = await undefined;
          arrGrp[rowGr].movil = await undefined;
          arrGrp[rowGr].reason_id = await undefined;
          arrGrp[rowGr].doctor_id = await undefined;
          arrGrp[rowGr].confirmed = await undefined;

          arrGrp[rowGr].wday = await undefined;
          arrGrp[rowGr].patient_id = await undefined;
          arrGrp[rowGr].timed = await undefined;
          arrGrp[rowGr].employee_id = await undefined;
          
          let arrDoc = [];
          let arrRea = [];
          
          for (var rowNN in await arrNext) {
            // arrNext[rowNN].doctorName = '';
            // arrNext[rowNN].reasonDesc = '';
            // arrNext[rowNN].color = '';

            if (await arrGrp[rowGr].year === arrNext[rowNN].year &&
                await arrGrp[rowGr].month === arrNext[rowNN].month &&
                await arrGrp[rowGr].day === arrNext[rowNN].day
            ) {
                arrDoc = [];
                arrRea = [];
                arrNext[rowNN].doctorName = await '';
                arrNext[rowNN].reasonDesc = await '';
                arrNext[rowNN].color = await 'grey';
                if (arrNext[rowNN].doctor_id != ''){
                  arrDoc = this.doctors.find(({ id }) => Number(id) === Number(arrNext[rowNN].doctor_id));
                  if (arrDoc) {
                    arrNext[rowNN]['doctorName'] = arrDoc.name;
                    arrNext[rowNN].color = arrDoc.color;
                  }
                }
                if (arrNext[rowNN].reason_id != ''){
                  arrRea = this.reasons.find(({ id }) => Number(id) === Number(arrNext[rowNN].reason_id));
                  if (arrRea) {
                    arrNext[rowNN]['reasonDesc'] = arrRea.description;
                  }
                }
                await arrGrp[rowGr].items.push(arrNext[rowNN]);
            }
          }
          
        }
        this.appointsGrp = await this.copyObjects(arrGrp);
        // await console.log(this.appointsGrp.length);
        





        
        // const createStart = await this.roundTime(nowTime);
      },
      //
      loadTab: async function (val) {
        await this.scrollToTop();
        this.tabNumber = await  val;
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000
        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      processData: function () {
        //
      }
    },
    created () {
      this.initialize();
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
      tabNumber (val) {
        if ( val === 0) {
          this.initialize();
        }
      }
    },
    computed: {
      //
    }
  }
</script>
