<template>
  <v-container>
    <v-col>
      <v-sheet>
        <v-toolbar
          flat
          >
          <v-btn
            outlined
            class="ma-0 pa-0"
            color="grey darken-2"
            @click="setToday"
            >
            <v-icon>
              mdi-calendar-today
            </v-icon>
            <span class="" v-show="screenSize != 'xs'">Hoy</span>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            class="ma-0 pa-0"
            @click="prev"
            >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
            class="ma-0 pa-0"
            >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
            class="ma-0 pa-0"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
                >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-select v-show="screenSize !== 'xs'"
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="Dias"
            class="ma-1 pa-0">
          </v-select>
          <v-btn
            outlined
            class="ma-0 pa-1"
            color="grey darken-2"
            @click.stop="btnReturn()"
            >
            <v-icon left>mdi-chevron-left</v-icon>
            <span class="" v-show="screenSize != 'xs'">Regresar</span>
          </v-btn>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="1100">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          locale="es-US"
          :value="valueDay"
          :now="nowDay"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          :weekdays="weekday"
          :event-ripple="false"
          :first-interval= 14
          :interval-minutes= 30
          :interval-count= 25
          :event-overlap-mode="'stack'"
          
          @click:more="viewDay"
          @click:date="viewDay"
          @change="getEvents"
          @mousedown:time="startTime"

          >
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
            >
            <v-toolbar
              :color="selectedEvent.color"
              dark
              >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
                >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      >
      <v-card>
        <v-card-title v-bind:class="arrDialog.color">
          <span v-bind:class="classCardTitle">{{this.getFullDate(arrDialog.start)}}</span>
          <v-spacer></v-spacer>

          <!-- v-btn icon class="px-7" v-show="arrDialog.id !== 0"
            @click.stop="dialogConfirm = true">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn -->
          <!--v-btn icon>
            <v-icon></v-icon>
          </v-btn -->
          <!-- v-btn icon class="px-7" v-show="arrDialog.id !== 0"
            @click.stop="btnMess(arrDialog)">
            <v-icon>mdi-message-text-outline</v-icon>
          </v-btn -->
        </v-card-title>
        <v-card-text class="py-0">
          <v-container>
            <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="7">
                <v-text-field v-on:keyup="keymonitor" ref="text_name" v-model="arrDialog.name" label="Nombre"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-on:keyup="keymonitor" ref="text_movil" v-model="arrDialog.movil" label="Telefono Movil" v-mask="maskTel"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select id="text_doctor" ref="text_doctor" 
                  v-model="arrDialog.doctor_id"
                  label="Doctor"
                  v-on:keyup="keymonitor"
                  :items="doctors"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select id="text_reason" ref="text_reason" 
                  v-model="arrDialog.reason_id"
                  label="Tratamiento"
                  v-on:keyup="keymonitor"
                  :items="reasons"
                  item-text="description"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="7">
                <v-text-field v-on:keyup="keymonitor" ref="text_comment" v-model="arrDialog.comment" label="Comentarios"></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions  class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text  @click="btnClose()">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="btnSave()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirm"
      max-width="70%" persistent
      >
      <v-card>
        <v-card-title class="red">
          <span v-bind:class="classCardTitle">Se cancelara la cita?</span>
        </v-card-title>
        <v-card-text>
          Por favor confirme.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirm = false"
            >
            Cerrar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="btnCancel()"
            >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'AppCal',
    props: [
      'itemDoc', 'itemRea', 'itemApp', 'item' // 'itemDoc', 'itemRea', 'itemApp', 'itemGrp', 'item'
    ],
    components: {
      //
    },
    data: () => ({
      //
      valueDay: null,
      nowDay: null,
      weekday: [1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Lun - Sab', value: [1, 2, 3, 4, 5, 6] },
        { text: 'Dom - Sab', value: [0, 1, 2, 3, 4, 5, 6] },
      ],
      //
      dialog: false,
      maskTel: '##-####-####',
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Dia',
        '4day': '4 Dias',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      doctors: [],
      reasons: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'lime darken-4'],
      namesOLD: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      appoints: [],
      arrDialog: [],
      dialogConfirm: false,
      //
      selectItemYN: [
        { item: 'Si', val: '1' },
        { item: 'No', val: '0' }
      ],
      //
    }),
    methods: {
      initialize: async function () {
        this.appoints = await this.copyObjects(this.itemApp);
        this.doctors = await this.copyObjects(this.itemDoc);
        this.reasons = await this.copyObjects(this.itemRea);
        await this.getEvents();
        this.nowDay = await this.item.year + '-' + this.item.month + '-' + this.item.day;
        this.valueDay = await this.item.year + '-' + this.item.month + '-' + this.item.day;
        this.focus = await this.item.year + '-' + this.item.month + '-' + this.item.day;
        this.type = await 'week';
        //
      },
      initializeOLD: async function () {
        await this.doctorList();
        await this.reasonList();
        await this.getList();
        this.nowDay = await this.item.year + '-' + this.item.month + '-' + this.item.day;
        this.valueDay = await this.item.year + '-' + this.item.month + '-' + this.item.day;
        this.focus = await this.item.year + '-' + this.item.month + '-' + this.item.day;
        this.type = await 'week';
      },
      getEvents () {
        // getEvents ({ start, end }) {
        this.events = [];
        const appoints = this.appoints;
        let arrDoc = [];
        let color = 'grey darken-3';
        for (var row in appoints) {
          // console.log(row);
          const mouse = this.toTime(appoints[row]);
          this.createStart = this.roundTime(mouse);
          color = 'grey darken-3';
          if (appoints[row].doctor_id != null) {
            arrDoc = this.doctors.find(({ id }) => Number(id) === Number(appoints[row].doctor_id));
            if (arrDoc) {
              color = arrDoc.color;
            }
          }
          this.createEvent = {
            id: appoints[row].id, name: appoints[row].name,  movil: appoints[row].movil, comment: appoints[row].comment,
            // color: appoints[row].doctor_id != null ? 'green' : 'grey darken-3', // this.rndElement(this.colors), 
            color: color,
            start: this.createStart, end: this.createStart, timed: appoints[row].timed,
            patient_id: appoints[row].patient_id, doctor_id: appoints[row].doctor_id, reason_id: appoints[row].reason_id, 
            // active: '1', // timed: `${appoints[row].timed}`,
          }
          //  this.doctors.id === appoints[row].doctor_id),
          this.events.push(this.createEvent)
        }
      },
      async startTime (tms) {
        // console.log(tms);
        // crea un evento
        this.arrDialog = await [];
        const mouseClick = await this.toTime(tms);
        this.createStart = await this.roundTime(mouseClick);
        let create = await true;
        const arr = await this.events;
        for (var rowId in await arr) {
          if (await arr[rowId].start === this.createStart) {
            create = await false;
            // this.arrDialog = await this.copyObjects(arr[rowId]);
            // this.arrDialog['date'] = await this.getDateStr(this.arrDialog.start);
          }
        }
        if (await create) {
          this.arrDialog = await this.copyObjects(this.item);
          this.arrDialog['start'] = await this.createStart;
          this.arrDialog['end'] = await this.createStart;
          this.arrDialog['date'] = await this.getDateStr(this.arrDialog.start);
          this.dialog = await true;
          await this.editFocus('text_name');
        }







        /*
        this.arrDialog = await [];
        const mouseClick = await this.toTime(tms);
        // await console.log(mouseClick);
        this.createStart = await this.roundTime(mouseClick);
        let create = await true;
        const arr = await this.events;
        for (var rowId in await arr) {
          if (await arr[rowId].start === this.createStart) {
            create = await false;
            this.arrDialog = await this.copyObjects(arr[rowId]);
            this.arrDialog['date'] = await this.getDateStr(this.arrDialog.start);
          }
        }
        // console.log(create);
        if (await create) {
          this.createEvent = await {
            id: 0, name: null, movil: null, comment: null,
            color: 'blue-grey', // this.rndElement(this.colors)
            start: this.createStart, end: this.createStart,
            timed: true, date: '',
            patient_id: '', doctor_id: '', reason_id: '', 
            // active: '0',
          }
          this.arrDialog = await this.copyObjects(this.createEvent);
          this.arrDialog['date'] = await this.getDateStr(this.arrDialog.start);
        }
        this.dialog = await true;
        await this.editFocus('text_name');
        */
      },
      getListOLD: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'appointlist';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.appoints = await dataResponse.data
          await this.getEvents();
        }
      },
      doctorListOLD: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'doctorlist';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.doctors = await dataResponse.data;
          // await alert(JSON.stringify(dataResponse));
        }
      },
      reasonListOLD: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'reasonlist';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.reasons = await dataResponse.data;
          // await alert(JSON.stringify(dataResponse));
        }
      },
      btnCancel: async function () {
        //
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'appointdelete';
          let formData = await this.arrDialog;
          formData['employee_id'] = await this.userData.id;
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          if (dataResponse) {
            let message = await dataResponse.message;
            let type = await 'info';
            await this.$store.dispatch('loadMessage', { msg: message, type: type });
            await this.getList();
            this.arrDialog.date = await [];
            this.dialogConfirm = await false;
            this.dialog = await false;
          }
        }
        //
      },
      btnMess: async function (val) {
        const dateStr = await this.getFullDate(val.start);
        let mess = await 'Le recordamos de su próxima cita el día' +  ' ' + await dateStr;
        let href = await 'https://wa.me/52' + await val.movil.replace(/-/g, '') + '?text=' + mess;
        await window.open(href, '_blank');
      },
      btnReturn: function () {
        this.$emit('setItemRow', []);
        this.$emit('tabChange', 0);
      },
      btnClose: function () {
        this.arrDialog = [];
        this.dialog = false;
      },
      btnSave: async function () {
        if(await this.validationFields()){
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          if (await token) {
            let apiRoute = await 'appointcreate';
            let formData = await this.arrDialog;
            formData['employee_id'] = await this.userData.id;
            const returnData = await true;
            let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
            // console.log(dataResponse);
            if (dataResponse) {
              let message = await dataResponse.message;
              let type = await 'info';
              await this.$store.dispatch('loadMessage', { msg: message, type: type });
              // await this.getList();
              this.arrDialog.date = await [];
              this.dialog = await false;
              await this.$emit('setItemRow', []);
              await this.$emit('tabChange', 0);
              /*
              await this.$emit('upList');
              await setTimeout(() => {
                this.$emit('upArr');
                this.$emit('tabChange', 1);
              }, 3000)
              */
            }
          }
        }
        //
      },
      validationFields: function () {
        let isValid = true;

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.name,
            'El Campo Nombre no puede ser blanco',
            'text_name'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.movil,
            'El Campo Telefono Movil no puede ser blanco',
            'text_movil'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.reason_id,
            'El Campo Tratamiento no puede ser blanco',
            'text_reason'
          );
          this.$refs.text_reason.blur();
        }
        

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.doctor_id,
            'El Campo Doctor no puede ser blanco',
            'text_doctor'
          );
          this.$refs.text_doctor.blur();
        }
        

        /*
        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.comment,
            'El Campo Comentarios no puede ser blanco',
            'text_comment'
          );
        }
        */
        return isValid;
      },
      //
      keymonitor: async function (event) {
        if (await event.key === 'Enter') {
          await this.btnSave();
        }
      },
      //
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000
        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      processData: function () {
        //
      }
    },
    created () {
      this.initialize();
    },
    mounted () {
      this.$refs.calendar.checkChange();
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      classCardTitle: function () {
        return {
          // 'blue--text': this.selected.items.length !== 0 ? true : false,
          // 'red--text': this.selected.items.length === 0 ? true : false,
          // 'headline': this.imageHeight === 'sm' ? true : false,
          // 'caption': this.imageHeight !== 'sm' ? true : false,
          'caption': this.screenSize === 'xs' ? true : false,
          'text-h6': this.screenSize !== 'xs' ? true : false,
          // 'light-blue darken-4': true,
          'white--text': true,
          //    headline
        }
      }
    }
  }
</script>
