<template>
  <v-container>
  <div>
    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''"
        >
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnExpand"
        @click.stop="btnExpand()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-text-subject
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >expandir lista
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnContract"
        @click.stop="btnContract()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-text-short
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >contraer lista
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="$router.push({name: 'Logged', params: {}})"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-chevron-left
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
    </v-toolbar>
    <v-expansion-panels
      v-model="panel"
      multiple
      
      >
      <v-expansion-panel
        v-for="(item, i) in filteredRecipes.filter(info => info.active === true)"
        :key="i"
        >
        <v-expansion-panel-header disable-icon-rotate>{{ getFullDate2(item.timestamp) }}
          <template v-slot:actions>
            <v-icon color="teal">
              mdi-calendar-multiselect
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        <v-card
          class="mx-auto"
          flat
          >
          <v-list>
            <v-list-item-group
              >
              
              <v-list-item
                v-for="child in item.items.filter(info => info.active === true)"
                  :key="child.timestamp"
                >
                <v-list-item-content>
                  
                  <v-list-item-title 
                    v-text="'Horario: ' + child.hour + ':' + child.minute + 
                            ' / Nombre: ' + child.name
                    ">
                  </v-list-item-title>
                  <v-list-item-title 
                    v-text="'Tratamiento / ' + child.reasonDesc
                    " v-show="child.reason_id != ''">
                  </v-list-item-title>
                  <v-list-item-title 
                    v-text="'Doctor / ' + child.doctorName
                    " v-show="child.doctor_id">
                  </v-list-item-title>
                  <v-list-item-title 
                    v-text="'Comentarios / ' + child.comment
                    " v-show="child.comment != null">
                  </v-list-item-title>
                  <v-list-item-subtitle 
                    v-text="'Pendiente por confirmar'
                    " v-if="child.confirmed === '0'" class="red--text">
                  </v-list-item-subtitle>
                  <v-list-item-subtitle 
                    v-text="'Cita confirmada'
                    " v-else class="green--text">
                  </v-list-item-subtitle>
                  <DIV>
                  <!-- a :href="`tel:${child.movil}`">{{(child.movil.replace( /(\d{2})(\d{2})(\d{6})/,'$1-$2-$3' )) }}</a -->
                  <a :href="`tel:${child.movil}`">
                    
                    <v-chip
                      class="ma-2"
                      color="light-blue darken-4"
                      outlined
                    >
                      <v-icon left>
                        mdi-phone
                      </v-icon>
                      {{(child.movil.replace( /(\d{2})(\d{2})(\d{6})/,'$1-$2-$3' )) }}
                    </v-chip>
                  </a>
                  &nbsp;
                  <v-chip
                    class="ma-2"  color="green" outlined @click.stop="btnMess(child)">
                      <v-icon left>
                        mdi-whatsapp
                      </v-icon>
                      Mensaje
                  </v-chip>
                  &nbsp;
                  <v-chip
                    class="ma-2"  color="light-blue" outlined @click.stop="btnDtl(child)">
                      <v-icon left>
                        mdi-information-variant
                      </v-icon>
                      Ver Mas Detalles
                  </v-chip>
                  <v-divider></v-divider>
                  </DIV>
                  

                </v-list-item-content>
                
              </v-list-item>
              
            </v-list-item-group>
            
          </v-list>
        </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      >
      <v-card>
        
        <v-card-title v-bind:class="arrDialog.color">
          <DIV>
          <span v-bind:class="classCardTitle">{{this.getFullDate(arrDialog.timestamp)}}</span>
          <!-- v-spacer></v-spacer -->
          </DIV>
          <DIV style="display: inline-block; text-align: right; width: 100%">
          <v-btn plain color="white" v-if="!isEditing"
            @click.stop="dialogConfirm = true">
            <v-icon class="ma-0 pa-1">mdi-trash-can-outline</v-icon>
            <span class="ma-0 pa-0" v-show="screenSize != 'xs'">eliminar</span>
          </v-btn>
          <v-btn plain color="white"
            @click.stop="btnUpdate(arrDialog)">
            <v-icon class="ma-0 pa-1">mdi-square-edit-outline</v-icon>
            <span class="ma-0 pa-0" v-show="screenSize != 'xs'">modificar</span>
          </v-btn>
          <v-btn plain color="white" v-if="!isEditing"
            @click.stop="btnDate()">
            <v-icon class="ma-0 pa-1">mdi-calendar-search</v-icon>
            <span class="ma-0 pa-0" v-show="screenSize != 'xs'">fecha</span>
          </v-btn>
          </DIV>
        </v-card-title>
        <v-card-text class="py-0">
          <v-container>
            <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="7">
                <v-text-field v-on:keyup="keymonitor" ref="text_name" v-model="arrDialog.name" label="Nombre" :disabled="!isEditing"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-on:keyup="keymonitor"
                  v-on:keypress="NumbersOnly"
                  v-on:input="acceptNumber(arrDialog.movil)"
                  v-mask="maskTel"
                  maxlength="12"
                  required pattern="[0-9\.]+"
                  ref="text_movil" v-model="arrDialog.movil" label="Telefono Movil" :disabled="!isEditing"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <!-- v-text-field v-on:keyup="keymonitor" ref="text_" v-model="arrDialog.doctorName" label="Doctor" :disabled="!isEditing"></v-text-field -->
                <v-select id="text_doctor" ref="text_doctor" 
                  v-model="arrDialog.doctor_id"
                  label="Doctor" :disabled="!isEditing"
                  v-on:keyup="keymonitor"
                  :items="doctors"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <!-- v-text-field v-on:keyup="keymonitor" ref="text_" v-model="arrDialog.reasonDesc" label="Tratamiento" :disabled="!isEditing"></v-text-field -->
                <v-select id="text_reason" ref="text_reason" 
                  v-model="arrDialog.reason_id"
                  label="Tratamiento" :disabled="!isEditing"
                  v-on:keyup="keymonitor"
                  :items="reasons"
                  item-text="description"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="7">
                <v-text-field v-on:keyup="keymonitor" ref="text_comment" v-model="arrDialog.comment" label="Comentarios" :disabled="!isEditing"></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions  class="pt-0">
          <span v-if="arrDialog.confirmed === '0'" class="red--text">Cita pendiente por confirmar</span>
          <span v-else class="green--text">Cita Confirmada</span>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text  @click="btnClose()" v-if="!isEditing">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text  @click="btnCancel()" v-if="isEditing">
            Cancelar
          </v-btn>
          <v-btn color="green" text @click="btnSave()" v-if="isEditing">
            Guardar
          </v-btn>
          <v-btn color="deep-orange" text @click="btnConfirm()" v-if="arrDialog.confirmed==='0' && !isEditing">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirm"
      max-width="70%" persistent
      >
      <v-card>
        <v-card-title class="red">
          <span v-bind:class="classCardTitle">Se cancelara la cita?</span>
        </v-card-title>
        <v-card-text>
          Por favor confirme.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirm = false"
            >
            Cerrar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="btnDelete()"
            >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
  <DIV v-show="urlApi.includes('localhost')">
    {{'itemDoc'}}<BR/>
    {{'itemRea'}}<BR/>
    {{'itemApp'}}<BR/>
    itemGrp*{{itemGrp}}*<BR/>
  </DIV>
  </v-container>
</template>
<script>
  export default {
    name: 'AppLst',
    props: [
      'itemDoc', 'itemRea', 'itemGrp', // 'itemDoc', 'itemRea', 'itemApp', 'itemGrp', 'item'
    ],
    components: {
      //
    },
    data: () => ({
      //
      //
      panel: [],
      selItems: null,
      //
      searchValue: null,
      showFind: false,
      //
      showBtnFind: true,
      showBtnClose: true,
      showBtnContract: true,
      showBtnExpand: false,
      buscara: [],
      items: [],
      //
      doctors: [],
      reasons: [],
      //
      dialog: false,
      arrDialog: [],
      arrEdit: [],
      isEditing: null,
      maskTel: '##-####-####',
      dialogConfirm: false,
      //
    }),
    methods: {
      initialize: async function () {
        this.doctors = await this.copyObjects(this.itemDoc);
        this.reasons = await this.copyObjects(this.itemRea);
        this.items = await this.copyObjects(this.itemGrp);
        
        this.panel = await [];
        for (var z = 0; z < await this.items.length; z++){
          await this.panel.push(z);
        }
        //
      },
      initializeOLD: async function () {

        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        this.items = await [];
        if (await token) {
          let formData = await {};
          let apiRoute = await 'appointlistGrp';
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // await console.log(dataResponse);
          this.items = await dataResponse.data;

          let myObj = await this.items;

          let arrDoc = [];
          let arrRea = [];
          let color = '';
          for (var i = await 0; i < await myObj.length; await i++){
            myObj[i].timestamp = await new Date(myObj[i].year, (myObj[i].month - 1), myObj[i].day, myObj[i].hour, myObj[i].minute).getTime();
            for (var z = await 0; z < await myObj[i].items.length; await z++){
              // console.log(myObj[z].items);
              arrDoc = [];
              arrRea = [];
              color = 'grey';
              myObj[i].items[z]['doctorName'] = '';
              myObj[i].items[z]['reasonDesc'] = '';
              if (myObj[i].items[z].doctor_id != ''){
                arrDoc = this.doctors.find(({ id }) => Number(id) === Number(myObj[i].items[z].doctor_id));
                if (arrDoc) {
                  myObj[i].items[z]['doctorName'] = arrDoc.name;
                  color = arrDoc.color;
                }
              }
              myObj[i].items[z]['color'] = color;
              if (myObj[i].items[z].reason_id != ''){
                arrRea = this.reasons.find(({ id }) => Number(id) === Number(myObj[i].items[z].reason_id));
                if (arrRea) {
                  myObj[i].items[z]['reasonDesc'] = arrRea.description;
                }
              }
              // console.log( i + ' ' + z + ' ' + myObj[i].items[z].doctor_id);
            }
            // console.log(i);
          }
        }
        //
      },
      //
      NumbersOnly () {
        // evt
        /*
        const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-'];
        const keyPressed = evt.key;
        
        if (!keysAllowed.includes(keyPressed)) {
              evt.preventDefault()
        }
        */
      },
      NumbersOnlyOLD(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 45
          ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      //
      acceptNumber() {
        // val
        /*
        var x = val.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        this.arrDialog.movil = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        */
      },
      confirmedOLD: function (val) {
        // alert('Chip close clicked')
        this.arrDialog = this.copyObjects(val);
        this.arrDialog.timestamp = new Date(this.arrDialog.year, (this.arrDialog.month - 1), this.arrDialog.day, this.arrDialog.hour, this.arrDialog.minute).getTime();
        // let timestamp = await new Date(val.year, (val.month - 1), val.day, val.hour, val.minute).getTime();
        this.dialog = true;
      },
      btnCancel: function () {
        this.arrDialog = this.copyObjects(this.arrEdit);
        this.isEditing = false;
      },
      btnDate: async function () {
        let arrUpdate = await this.copyObjects(this.arrDialog);
        await this.$emit('setItemRow', arrUpdate);
        await this.$emit('tabChange', 2);
      },
      btnSave: async function () {
        if(await this.validationFields()){
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          if (await token) {
            let apiRoute = await 'appointcreate';
            let formData = await this.arrDialog;
            formData['employee_id'] = await this.userData.id;
            const returnData = await true;
            let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
            // console.log(dataResponse);
            if (dataResponse) {
              let message = await dataResponse.message;
              let type = await 'info';
              await this.$store.dispatch('loadMessage', { msg: message, type: type });
              await this.initialize();
              this.arrDialog.date = await [];
              this.isEditing = await false;
              this.dialog = await false;
              /*
              await this.$emit('upList');
              await setTimeout(() => {
                this.$emit('upArr');
                // this.$emit('tabChange', 1);
              }, 3000)
              */
              this.$emit('tabChange', 0);

            }
          }
        }
        //
      },
      validationFields: function () {
        let isValid = true;

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.name,
            'El Campo Nombre no puede ser blanco',
            'text_name'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.movil,
            'El Campo Telefono Movil no puede ser blanco',
            'text_movil'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.reason_id,
            'El Campo Tratamiento no puede ser blanco',
            'text_reason'
          );
          this.$refs.text_reason.blur();
        }
        

        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.doctor_id,
            'El Campo Doctor no puede ser blanco',
            'text_doctor'
          );
          this.$refs.text_doctor.blur();
        }
        

        /*
        if(isValid===true){
          isValid = this.isEmpty(
            this.arrDialog.comment,
            'El Campo Comentarios no puede ser blanco',
            'text_comment'
          );
        }
        */
        return isValid;
      },
      btnUpdate: function () {
        this.isEditing = true;
        this.getFocus('text_name');
        // val.timestamp = new Date(val.year, (val.month - 1), val.day, val.hour, val.minute).getTime();
        // this.$router.push({name: 'Appoint', params: {message: 'Elija una nueva fecha para asignar', item: val}});
      },
      btnDelete: async function () {
        //
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'appointdelete';
          let formData = await this.arrDialog;
          formData['employee_id'] = await this.userData.id;
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          if (dataResponse) {
            let message = await dataResponse.message;
            let type = await 'info';
            await this.$store.dispatch('loadMessage', { msg: message, type: type });
            await this.initialize();
            this.arrDialog.date = await [];
            this.dialogConfirm = await false;
            this.dialog = await false;
          }
        }
      },
      btnClose: function () {
        this.dialog = false;
        this.isEditing = false;
        this.arrDialog = [];
        // this.arrDoc.movil = this.arrDoc.movil.replace(/-/g, '');
      },
      btnDtl: function (val) {
        this.arrDialog = this.copyObjects(val);
        this.arrEdit = this.copyObjects(val);
        this.arrDialog.movil = this.maskTelephone(this.arrDialog.movil);
        this.arrEdit.movil = this.maskTelephone(this.arrEdit.movil);
        this.arrDialog.timestamp = new Date(val.year, (val.month - 1), val.day, val.hour, val.minute).getTime();
        this.dialog = true;
      },
      maskTelephone: function (val) {
        let x = val.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        return !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
      },
      btnConfirm: async function () {
        // this.dialog = false;
        //
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'appointconfirm';
          let formData = await this.arrDialog;
          formData['employee_id'] = await this.userData.id;
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          if (dataResponse) {
            let message = await dataResponse.message;
            let type = await 'info';
            await this.$store.dispatch('loadMessage', { msg: message, type: type });
            // await this.getList();
            // this.arrDialog.date = await [];
            await this.initialize();
            this.dialog = await false;
          }
        }
        //
      },
      keymonitor: async function (event) {
        if (await event.key === 'Enter') {
          await this.btnSave();
        }
      },
      cancelFind: async function () {
        this.searchValue = await null;
        this.showFind = await false;
      },
      btnContract: function () {
        this.showBtnContract = false;
        this.showBtnExpand = true;
        this.panel = [];
      },
      btnExpand: function () {
        this.showBtnContract = true;
        this.showBtnExpand = false;
        this.panel = [];
        for (var z = 0; z < this.items.length; z++){
          this.panel.push(z);
        }
      },
      btnFind: async function () {
        this.showFind = await !this.showFind;
        if(await this.showFind){
          await setTimeout(() => {
            this.$refs.text_find.focus();
          }, 500);
        } else {
          this.searchValue = await null;
        }
      },
      btnMess: async function (val) {
        // const dateStr = await this.getFullDate(new Date(val.timestamp));
        // alert(val.year);
        let timestamp = await new Date(val.year, (val.month - 1), val.day, val.hour, val.minute).getTime();
        const dateStr = await this.getFullDate(new Date(timestamp));
        // alert(dateStr);
        //
        let mess = await 'Le recordamos de su próxima cita el día' +  ' ' + await dateStr;
        let href = await 'https://wa.me/52' + await val.movil.replace(/-/g, '') + '?text=' + mess;
        await window.open(href, '_blank');
      },
    },
    created () {
      //
      this.initialize();
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
      searchValue () {
        this.panel = [];
        for (var z = 0; z < this.items.length; z++){
          this.panel.push(z);
        }
      },
      itemGrp (val) {
        this.items = this.copyObjects(val);
      }
    },
    computed: {
      filteredRecipes() {
        let tempRecipes = [];
        if (this.items.length != 0){
          tempRecipes = this.copyObjects(this.items);
        }
        if (this.searchValue !== '' && this.searchValue) {
          for (var x = 0; x < tempRecipes.length; x++){
            tempRecipes[x].active = false;
            for (var y = 0; y < tempRecipes[x].items.length; y++){
              // console.log(tempRecipes[x].items[y]);
              if ( tempRecipes[x].items[y].name.toUpperCase().includes(this.searchValue.toUpperCase()) ||
                   tempRecipes[x].items[y].movil.toUpperCase().includes(this.searchValue.toUpperCase())
              ) {
                tempRecipes[x].items[y].active = true;
                tempRecipes[x].active = true;
              } else {
                tempRecipes[x].items[y].active = false;
              }
            }
          }
        }
        return tempRecipes;
      },
      classCardTitle: function () {
        return {
          // 'blue--text': this.selected.items.length !== 0 ? true : false,
          // 'red--text': this.selected.items.length === 0 ? true : false,
          // 'headline': this.imageHeight === 'sm' ? true : false,
          // 'caption': this.imageHeight !== 'sm' ? true : false,
          'caption': this.screenSize === 'xs' ? true : false,
          'text-h6': this.screenSize !== 'xs' ? true : false,
          // 'light-blue darken-4': true,
          'white--text': true,
          //    headline
        }
      },
    }
  }
</script>
